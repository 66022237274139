.mintpass-sorry-msg {
  width: 70%;
  margin: 0 auto;
}

.mintpass-sorry-msg p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.airdrop-mint-between-place {
  height: 50px;
}

.backBtnMnt {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #2407d8;
  text-decoration: underline;
  cursor: pointer;
}

.intro-gif-success {
  width: 222px;
  height: 220px;
  border-radius: 160px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.droplove-page-create-radio-form {
  padding-top: 40px;
}

.droplove-page-create-max-supply-form {
  padding-bottom: 40px;
}

.p-small {
  font-size: 12px !important;
}

.droplove-page-create-radio-form label span {
  font-size: 16px;
  margin-left: 5px;
}

h2.accordion-header button {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  background: transparent;
  border: none;
}

button.accordion-button label {
  margin-right: 5px;
}

.droplove-page-create-datetime-picker {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  width: 250px;
  
}

.droplove-page-create-datetime-picker input {
  font-family: "Millik";
  font-weight: 400;
  font-size: 18px;
  color: #181743;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.droplove-page-create-datetime-picker p {
  margin: 0 auto;
}
