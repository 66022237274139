/* .droplove-header {
  display: none;
} */

/* .mint-page-header {
  display: flex;
  padding: 20px 80px; */
/* } */

.mint-page-body {
  padding-top: 100px;
}

.mint-page-right-info {
  /* padding-top: 40px; */
}

.mint-page-right-info-disconneted {
  padding: 80px 60px;
}

.mint-page-right-info-disconneted h1 {
  font-family: "Millik";
  font-size: 38px;
  font-weight: 400;
  color: #181743;
  margin: 30px auto;
}

.mint-page-right-info-disconneted p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #181743;
  margin: 50px auto;
}

.mint-page-right-info h1 {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}

.mint-page-right-token-info {
  margin-bottom: 30px;
}

.mint-page-right-website {
  margin-bottom: 30px;
}

.mint-page-right-description {
  margin-bottom: 30px;
}

.mint-page-right-description p {
  padding: 0px 60px;
}

.pm-full-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  background: #181743;
  border-radius: 40px;
  border-color: #181743;
  padding: 24px;
}

.pm-full-btn:hover {
  color: #181743;
  background: #dfe8ff;
  border-color: #dfe8ff;
}

.pm-small-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  background: #181743;
  border-radius: 40px;
  border-color: #181743;
  padding: 10px;
}

.pm-small-btn:hover {
  color: #181743;
  background: #dfe8ff;
  border-color: #dfe8ff;
}

.pm-mint-counter {
  margin: 30px 0px;
}

span.pm-mint-counter-info {
  font-family: "Poppins", sans-serif;
  color: #181743;
  font-weight: 600;
  font-size: 32px;
  vertical-align: middle;
}

.pm-mint-counter img {
  margin: 0px 30px;
  cursor: pointer;
}

.mint-page-collection-header {
  text-align: left;
  padding: 25px 110px;
}

.mint-page-collection-header h1 {
  font-weight: 600;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}

.mint-page-collection-header p {
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

.connector-indicator-green {
  width: 12px;
  height: 12px;
  background-color: green;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 50px;
}

.connector-indicator-red {
  width: 12px;
  height: 12px;
  background-color: red;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 50px;
}

.mint-success-dialog {
  max-width: 400px;
  width: 400px;
  text-align: center;
}

.mint-success-dialog-body {
  margin: 0 auto;
  padding: 60px 30px;
}

.mint-success-dialog-body h1 {
  font-weight: 600;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}

.mint-success-dialog-body p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.mint-page-left {
  /* display: flex;
  align-items: center;
  position: relative;
  text-align: center;
  min-height: 600px; */
}

.mint-page-left-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.mint-page-left-image img {
  max-width: 100%;
  max-height: 600px;
}
