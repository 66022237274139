.droplove-page-dashboard-body {
    padding-top: 150px;
    min-height: 700px;
    padding-left: 80px;
    padding-right: 80px;
}

.dashboard-top-header {
    width: 100%;
    text-align: left;
}

.dashboard-top-header h1 {
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    font-weight: 600;
    font-size: 32px;
    color: #000;
}

.network-selector {
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
}

.network-selector-label {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #000;
    float: right;
}

.netowrk-selector-input {
    float: right;
    margin-left: 30px;
}

#networkFormControl {
    border-radius: 30px;
    width: 260px;
}

.dashboard-profile-pic {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.dashboard-profile-name {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.dashboard-profile-name h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #181743;
}

.dashboard-profile-wallet {
    background: #181743;
    border-radius: 28px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    padding: 12px 20px;
    width: 150px;
    margin-bottom: 60px;
}

.dashboard-btn-edit-div {
    margin-bottom: 40px;
    width: 100%;
    height: 50px;
}

.dashboard-btn-edit-profile {
    background-color: #DFE8FF;
    border: 1px solid #DFE8FF;
    border-radius: 28px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2407D8;
    padding: 12px 20px;
    float: left;
}

.dashboard-profile-info {
    text-align: left;
    border-bottom: 1px dashed #DDE6EA;
    margin-bottom: 30px;
}

.dashboard-profile-info h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #181743;
    margin-bottom: 20px;
}

.dashboard-profile-info ul {
    padding-left: 0px;
}

.dashboard-profile-info ul li {
    padding-bottom: 20px;
    list-style: none;
}

.dashboard-profile-info ul li a {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #2407D8;
}

.dashboard-profile-info ul li a:hover {
    cursor: pointer;
}

.dashboard-profile-info-joined h4 {
    color: #181743;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    float: left;
    margin-top: 0;
}

.dashboard-profile-info-joined p {
    color: #181743;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
}

.contract-list-add-new {
    width: 100%;
    min-height: 400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 47px #bebdff;
    border-radius: 35px;
    /* position: relative; */
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 25px;
}

.contract-list-add-new-inside {
    margin: 0 auto;
}

.contract-list-add-new-inside img {
    margin-bottom: 20px;
}

.contract-list-add-new p {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #5034FF;
}

.contract-list a:hover {
    text-decoration: none;
}

.dashboard-pls-sign-message {
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.dashboard-pls-sign-message p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #181743;
}