@font-face {
  font-family: "Millik";
  font-style: normal;
  font-weight: normal;
  src: local("Millik"), url(../../fonts/Millik.otf) format("opentype");
}

.no-padding {
  padding: 0 !important;
}

body {
  background-image: url(../../assets/intro_background.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}

.full-btn {
  background-color: #181743;
  padding: 12px 28px;
  color: white;
  border-radius: 28px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.apply-btn {
  color: #181743;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}

.droplove-menu ul li a {
  padding-left: 3rem;
  position: relative;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.droplove-homepage-body {
  padding-top: 100px;
}

.intro-section {
  padding: 50px;
}

.intro-section h1 {
  font-family: "Millik";
  font-size: 38px;
  font-weight: 400;
  color: #181743;
  margin: 50px auto;
  width: 800px;
}

.intro-section p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #181743;
  margin: 50px auto;
  width: 600px;
}

.btn-border {
  background-color: #fff;
  padding: 12px 20px;
  color: #181743;
  border-radius: 28px;
  border: 3px solid #181743;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.btn-border-darkblue {
  background-color: white;
  padding: 12px 20px;
  color: #181743;
  border-radius: 28px;
  border: 3px solid #181743;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.btn-border-burp {
  color: #5034ff;
  background-color: white;
  padding: 12px 20px;
  border-radius: 28px;
  border: 3px solid #5034ff;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.intro-gif {
  width: 290px;
  height: 220px;
  border-radius: 160px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.mintpass-section {
  padding: 130px 80px;
  border: 2px solid #6d6af4;
  box-sizing: border-box;
  box-shadow: 0px 0px 47px rgb(190 189 255);
  border-radius: 35px;
  width: 60%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: #fff;
}

.mintpass-section-top ul {
  margin-bottom: 25px;
}

.mintpass-section-top ul li {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.mintpass-section-top img {
  width: 100px;
}

.mintpass-section-top h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin: 35px 20px;
}

.mintpass-section-top p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: #181743;
}

.mint-counter {
  margin: 30px 0px;
}

span.mintpass-counter {
  font-family: "Poppins", sans-serif;
  color: #2407d8;
  font-weight: 600;
  font-size: 32px;
  vertical-align: middle;
}

.mint-counter img {
  margin: 0px 30px;
  cursor: pointer;
}

.apply-btn-mintpass {
  color: #5034ff;
}

.full-btn-big {
  background-color: #5034ff;
  padding: 20px 24px;
  color: white;
  border-radius: 28px;
  border: none;
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.full-btn-small {
  background-color: #dfe8ff;
  padding: 20px 24px;
  color: #5034ff;
  border-radius: 28px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.full-btn-big:disabled {
  background-color: #bec7cc;
}

.supply-counter {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin: 25px 0px;
}

.price-info {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin: 25px 0px;
}

.upcoming-drop-section {
  padding: 100px 0px;
}

.upcoming-drop-title {
  margin-bottom: 40px;
  border-bottom: 1px solid #000;
}

.upcoming-drop-title h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: black;
  text-align: left;
}

.upcoming-drop-title .homepage-right-view-all {
  text-align: right;
  margin-bottom: 10px;
}

.upcoming-drop-title .homepage-right-view-all a {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #616161;
}

.homepage-right-view-all a:hover {
  text-decoration: none;
}

.upcoming-drop-window {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  padding: 30px 0px;
  /* width: 70%; */
  margin: auto;
  box-shadow: 0px 0px 47px rgb(190 189 255);
  background: #ffffff;
  border-radius: 35px;
  margin-bottom: 20px;
}

.udw-md-margin {
}

.upcoming-img {
  margin-bottom: 30px;
}

.upcoming-img img {
  border-radius: 35px;
  min-height: 300px;
}

.upcoming-info-avatar {
  padding: 15px;
}

.upcoming-info span {
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #181743;
}

.full-btn-upcomming {
  background-color: #5034ff;
  padding: 12px 20px;
  color: white;
  border-radius: 28px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.full-btn-upcomming:disabled {
  background-color: #bec7cc;
}

.upcoming-info {
  text-align: center;
  padding: 0px 30px;
}

.upcoming-info h2 {
  font-family: "Millik";
  font-size: 24px;
  font-weight: 400;
  color: #181743;
  margin: 20px 0px;
}

.upcoming-info p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #181743;
  margin: 20px 0px;
}

.mintpass-section-top p {
  text-align: center;
}

.macky {
  width: 210px;
  transform: rotate(-20deg);
  position: absolute;
  top: 40px;
  left: 80px;
}

.ruka {
  width: 280px;
  /* transform: rotate(20deg); */
  position: absolute;
  right: 100px;
  bottom: 50px;
}

.macky-mobile,
.ruka-mobile {
  display: none;
}

/* NEW STYLES */

.advantages-section {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 100px;
  margin-right: 100px;
}

.advantages-single {
  border-radius: 33px;
  padding: 40px;
  min-height: 260px;
}

.as-1 {
  background: linear-gradient(
    283.58deg,
    rgba(164, 253, 214, 0.3) 1.48%,
    rgba(103, 108, 252, 0.3) 130.33%
  );
  /* margin-right: 30px; */
}

.as-2 {
  background: rgba(161, 245, 218, 0.3);
  /* margin-right: 30px; */
}

.as-3 {
  background: linear-gradient(
    256.86deg,
    rgba(118, 142, 245, 0.3) -6.8%,
    rgba(163, 251, 215, 0.3) 98.69%
  );
}

.as-4 {
  background: linear-gradient(
    258.58deg,
    rgba(164, 253, 216, 0.3) -1.81%,
    rgba(102, 106, 253, 0.3) 133.45%
  );
}

.as-5 {
  background: rgba(161, 245, 218, 0.3);
}

.as-6 {
  background: linear-gradient(
    287.1deg,
    rgba(118, 143, 244, 0.3) -7.69%,
    rgba(163, 250, 215, 0.3) 99.05%
  );
}

.advantages-single img {
  max-height: 50px;
  margin-bottom: 10px;
}

.advantages-single h2 {
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.advantages-single p {
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #000;
}

/* END OF NEW STYLES */

/* NEW STYLES */

.droplove-banner-section {
  background: linear-gradient(255.49deg, #e0dfff 15.75%, #edfff7 84.25%);
  position: relative;
  padding: 80px 100px;
}

.banner-sc h2 {
  font-family: "Millik";
  font-size: 38px;
  font-weight: 400;
  color: #181743;
  margin: 50px auto;
}

.banner-sc p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.banner-sc {
  margin-bottom: 40px;
}

.sc-single {
  padding: 40px;
}

.sc-single h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

.sc-single p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.banner-sc-btn {
  /* text-align: center; */
  margin-top: 20px;
}

.sc-adv {
  display: inline-block;
}

.mintpass-section-top-miniheading p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #181743;
  margin-bottom: 30px;
}

.section-newsletter {
  background: radial-gradient(34.18% 89.22% at 50.32% 50%, rgba(251, 187, 0, 0.3) 9.11%, rgba(255, 255, 255, 0) 69.53%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 33px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.section-newsletter h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #000;
  margin-bottom: 15px;
}

.section-newsletter p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  margin-bottom: 15px;
}

.section-newsletter button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #181743;
  background: #ffffff;
  border: 3px solid #181743;
  border-radius: 28px;
  padding: 12px 20px;
}

/* END OF NEW STYLES */

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .mobile-hide {
    display: none;
  }

  .desktop-hide {
    display: block;
  }

  .droplove-header {
    padding: 20px 20px;
  }

  .intro-section {
    padding: 10px 0px 40px 0px;
  }

  .mintpass-section {
    width: auto;
    padding: 50px 25px;
  }

  .upcoming-drop-window {
    display: block;
    width: auto;
  }

  .upcoming-img {
    margin-bottom: 40px;
  }

  .upcoming-img img {
    width: 90%;
  }

  .droplove-menu {
    padding-top: 10px;
  }

  .droplove-menu ul li a img {
    width: 28px;
  }

  .intro-section h1 {
    width: auto;
  }

  .intro-section p {
    width: auto;
  }

  .droplove-banner-section p {
    width: 100%;
  }

  .macky-mobile {
    display: block;
    width: 220px;
  }

  .ruka-mobile {
    display: block;
    width: 220px;
  }

  .macky,
  .ruka {
    display: none;
  }

  .footer-right {
    text-align: left;
  }

  /* NEW RESPONSIVE STYLES */

  .advantages-section {
    margin-left: 0px;
    margin-right: 0px;
  }

  .advantages-single {
    margin-bottom: 20px;
  }

  .droplove-banner-section {
    padding-left: 0px;
    padding-right: 0px;
  }

  .upcoming-drop-title {
    margin-left: 5px;
    margin-right: 5px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .mobile-hide {
    display: none;
  }
}

.desktop-hide {
  display: none;
}
