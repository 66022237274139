.droplove-page-create-body {
    padding-top: 200px;
    min-height: 700px;
}

.droplove-page-create-body h1 {
    font-family: "Millik";
    font-size: 38px;
    font-weight: 400;
    color: #181743;
    margin: 30px auto;
}

.droplove-page-create-body p {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #181743;
    margin: 50px auto;
    /* text-align: left; */
}

.how-to-ipfs-uri {
    margin-bottom: 50px;
}

.how-to-ipfs-uri a {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #2407D8;
}

.create-welcome-section img {
    margin-bottom: 50px;
}

.btn-white-wizard {
    background-color: #fff;
    padding: 12px 20px;
    color: #2407D8;
    border-radius: 28px;
    border: 3px solid #2407D8;
    font-weight: 600;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    margin: 0px 15px 0px 15px;
}

.create-wizard-input {
    width: 500px;
    /* height: 56px; */
    /* left: 598.5px;
    top: 241px; */
    background: #FFFFFF;
    border: 1px solid #181743;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px 2px rgb(34 73 237 / 30%);
    border-radius: 8px;
    margin-bottom: 50px;
    font-size: 16px;
    padding-left: 10px;
}

.create-wizard-input input {
    font-size: 16px;
}

.wizard-back-button img {
    width: 60px;
    position: absolute;
    left: 150px;
    top: 140px;
    cursor: pointer;
}

.MuiInputBase-input:focus {
    outline: none !important;
}

.Mui-focused, .MuiInputBase-root, .MuiOutlinedInput-root, .MuiInputBase-formControl {
    outline: none !important;
    border-color: #fff;
}

label#maxSupply-input-label,
label#name-input-label,
label#ticker-input-label,
label#name-input-label,
label#website-input-label,
label#twitter-input-label,
label#discord-input-label,
label#mint-tokenId-input-label,
label#mint-wallet-input-label,
label#collection-description-input-label,
label#contract-name-input-label,
label#contract-uri-input-label,
label#metadata-name-input-label,
label#metadata-description-input-label,
label#metadata-website-input-label {
    font-size: 16px;
}

fieldset.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline {
    border: none;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -18px) scale(0.75) !important;
}

.loading_progress_bar {
    margin-top: 60px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.loading_progress_bar img {
    width: 100px;
}

.wizard-btn-steps {
    margin-top: 50px;
}

.connect-your-wallet-step-metamask {
    padding: 20px 60px;
    background: #FFFFFF;
    border: 3px solid #181743;
    border-radius: 40px;
    font-size: 24px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #181743;
}

button.connect-your-wallet-step-metamask img {
    margin-right: 10px;
}

.step-network-selector-input {
    width: 500px;
    margin: 0 auto;
}

#stepNetworkFormControl {
    border: 1px solid #5034FF;
    box-shadow: 0px 0px 3px 2px rgba(34, 73, 237, 0.3);
    border-radius: 30px;
    height: 50px;
    color: #3F474B;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.create-step-silent-mint-section ul {
    padding: 0;
    margin-bottom: 80px;
}

.create-step-silent-mint-section ul li {
    list-style: none;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #181743;
    text-align: left;
    margin-bottom: 30px;
}

.create-step-silent-mint-section ul li img {
    margin-right: 50px;
}

.create-step-section-column {
    padding-left: 20px;
    padding-right: 20px;
}

.create-step-section-column p {
    text-align: left;
}

@media screen and (max-width: 992px) {
    .create-welcome-section img {
        width: 100%;
    }

    .create-step-silent-mint-section {
        /* margin-top: 80px; */
    }

    .wizard-back-button img {
        left: 5%;
    }

    .create-step-silent-mint-section ul li {
        font-size: 14px;
    }

    .create-step-silent-mint-section ul li img {
        margin-right: 5px;
    }

    .create-step-silent-mint-section ul li {
        margin-bottom: 15px;
    }

    .create-step-section-column {
        margin-top: 80px;
    }

    .create-step-silent-mint-section ul {
        margin-bottom: 40px;
    }
}