.droplove-footer {
  padding: 40px 40px;
}

.droplove-footer p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #181743;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
}

.droplove-footer {
  padding: 80px 100px 25px 100px;
}

.footer-left {
  text-align: left;
}

.footer-left img {
  width: 56px;
}

.footer-menu ul li a img {
  width: 28px !important;
}

.footer-right {
  text-align: right;
  margin-top: 40px;
}

.footer-right ul li {
  list-style: none;
  margin-bottom: 10px;
  text-align: center;
}

.footer-right a {
  color: #5034FF;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}

.footer-right a:hover {
  color: #5034FF;
}

.footer-menu {
  flex: auto;
}

.footer-menu ul {
  list-style: none;
  text-align: left;
  padding-left: 0;
}

.footer-menu ul li a {
  padding-right: 30px;
}

.footer-below p {
  text-align: center;
  margin-bottom: 0;
  margin-top: 60px;
}

.rarity-sniper-logo {
  width: 70px;
}