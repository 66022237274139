.droplove-header {
  display: flex;
  padding: 20px 80px;
}

.droplove-menu ul {
  list-style: none;
  text-align: right;
}

.droplove-menu ul li span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  color: #181743;
  cursor: pointer;
  width: 200px;
}

.droplove-logo img {
  float: left;
}

.droplove-logo .logo-left {
  height: 45px;
}

.droplove-logo h1 {
  float: left;
  color: #000;
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  margin-top: 7px;
}

.droplove-logo {
  flex: auto;
  /* display: flex; */
  /* justify-content: flex-start; */
}

.droplove-menu {
  flex: auto;
  /* display: flex; */
  /* justify-content: flex-end; */
}

.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

@media screen and (max-width: 992px) {
  .droplove-logo h1 {
    display: none;
  }
}
