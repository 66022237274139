.dashboard-card {
    width: 100%;
    height: 400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 47px #bebdff;
    border-radius: 35px;
    position: relative;
    /* text-align: center;
    display:table-cell;
    vertical-align:middle; */
    padding-top: 145px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.dashboard-card-top {
    width: 100%;
    height: 100px;
    background: #181743;
    border-radius: 0px 0px 33px 33px;
    position: absolute;
    top: 0px;
}

.dashboard-card-top-public-mint {
    width: 100%;
    height: 100px;
    background: #9430F1; 
    border-radius: 0px 0px 33px 33px;
    position: absolute;
    top: 0px;
}

.dashboard-card h3 {
    font-family: "Millik";
    font-size: 24px;
    font-weight: 400;
    color: #000;
    margin-bottom: 15px;
    margin-top: 0px;
    word-break: break-all;
    padding-left: 20px;
    padding-right: 20px;
}

.dashboard-card h4 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000;
    margin-bottom: 15px;
}

.dashboard-edit-btn {
    background-color: #5034ff;
    padding: 12px 20px;
    color: white;
    border-radius: 28px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    margin-bottom: 15px;
}

.dashboard-ethscan-btn {
    background-color: #fff;
    padding: 12px 20px;
    color: #2407D8;
    border-radius: 28px;
    border: 3px solid #2407D8;
    font-weight: 600;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    margin: 0px 15px 0px 15px;
}