.profile-back-button {
    text-align: left;
    /* margin-bottom: 70px; */
    position: fixed;
    top: 182px;
}

.side-menu-edit-profile {
    margin-top: 180px;
}

.side-menu a:hover {
    text-decoration: none;
}

.side-menu-item-btn {
    width: 100%;
    padding: 12px 20px;
    background: #DFE8FF;
    border: 1px solid #DFE8FF;
    border-radius: 28px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2407D8;
    margin-bottom: 10px;
}

.side-menu-item p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.side-menu-item {
    padding: 25px;
    box-shadow: 0px 0px 74px rgba(190, 189, 255, 0.2);
    background: #fff;
    border: 1px solid rgba(190, 189, 255, 0.2);
    margin-bottom: 10px;
}

.side-menu-item:hover {
    border: 1px solid #2407D8;
}

.edit-top-header {
    text-align: left;
    padding-left: 25px;
}

.edit-top-header h1 {
    font-family: "Millik";
    font-size: 60px;
    font-weight: 400;
    color: #000;
    margin-bottom: 70px;
}

.edit-links {
    text-align: left;
    padding-left: 25px;
    margin-bottom: 100px;
}

.edit-links h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #181743;
    margin-bottom: 20px;
}

.edit-links ul {
    padding-left: 0px;
}

.edit-links ul li {
    padding-bottom: 30px;
    list-style: none;
}

.edit-links ul li a {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #2407D8;
}

.edit-links ul li a:hover {
    cursor: pointer;
}

.edit-links ul li img {
    width: 25px;
    margin-right: 10px;
    padding-top: 15px;
}

.edit-profile-input {
    width: 350px;
    /* height: 56px; */
    /* left: 598.5px;
    top: 241px; */
    background: #FFFFFF;
    border: 1px solid #181743 !important;
    box-sizing: border-box;
    /* box-shadow: 0px 0px 3px 2px rgb(34 73 237 / 30%); */
    border-radius: 8px;
    margin-bottom: 50px;
    font-size: 16px;
    padding-left: 10px;
}

.save-button {
    border-bottom: 1px dashed #DDE6EA;
    padding-bottom: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.save-edit-btn {
    /* width: 182px; */
    padding: 12px 20px;
    background: #5034FF;
    border-radius: 28px;
    border: 1px solid #5034FF;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
}

.delete-profile-button {
    text-align: left;
}

.delete-profile-btn {
    background: #FFFFFF;
    border: 1px solid #7F8A8F;
    border-radius: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #3F474B;
    padding: 16px;
    margin-right: 20px;
}

#name-input, 
#website-input,
#twitter-input,
#discord-input{
    font-size: 16px;
}

p#name-input-helper-text,
p#website-input-helper-text,
p#twitter-input-helper-text,
p#discord-input-helper-text {
    font-family: "Millik";
    font-size: 16px;
    font-weight: 400;
    color: #000;
}