.pd-0 {
    padding: 0;
  }
  
  .pdl-0 {
    padding-left: 0;
  }
  
  .pdr-0 {
    padding-right: 0;
  }
  
  .contract-pm-detail-header-element {
    background: #9430F1;
    width: 100%;
    height: 80px;
    border-radius: 0px 0px 33px 33px;
    position: fixed;
    top: 85px;
    z-index: 1000;
  }
  
  .side-menu {
    /* position: fixed; */
    /* top: 250px; */
    min-width: 350px;
    max-width: 380px;
    /* left: 50px; */
    /* z-index: 1000; */
  }
  
  .contract-detail-back-button {
    position: absolute;
    top: 30px;
    left: 50px;
  }
  
  .contract-detail-side-menu {
    margin-top: 100px;
    /* position: fixed;
      max-width: 380px; */
  }
  
  .contract-detail-sections-body {
    margin-bottom: 100px;
  }
  
  .pm-detail-top-header {
    padding-left: 25px;
    margin-bottom: 40px;
    padding-top: 80px;
  }
  
  .pm-detail-top-header h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #181743;
    margin-bottom: 15px;
  }
  
  .pm-detail-top-header h1 {
    font-family: "Millik";
    font-size: 60px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
  }
  
  .pm-detail-top-header h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #181743;
    margin-bottom: 30px;
  }
  
  .pm-detail-top-header-button {
    background-color: #dfe8ff;
    border: 1px solid #dfe8ff;
    border-radius: 28px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2407d8;
    padding: 12px 20px;
  }
  
  .detail-test-net-indicator {
    text-align: left;
    margin-bottom: 40px;
  }
  
  .detail-test-net-indicator button {
    background: #ffffff;
    border: 1px solid #7f8a8f;
    border-radius: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #3f474b;
    padding: 16px;
    cursor: auto;
  }
  
  .detail-section-mint-tokens {
    padding: 50px;
    text-align: left;
    background: linear-gradient(
      266.18deg,
      rgba(123, 97, 255, 0.5) 17.16%,
      rgba(255, 206, 64, 0.5) 90.07%
    );
    border-radius: 30px;
    margin-bottom: 40px;
  }
  
  .detail-section-mint-tokens h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 10px;
    margin-top: 0;
  }
  
  .detail-section-mint-tokens p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
  }
  
  .detail-section-mint-tokens h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #181743;
    margin-bottom: 20px;
  }
  
  .contract-detail-input {
    width: 180px;
    background: #ffffff;
    border: 1px solid #181743 !important;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 10px !important;
  }
  
  .contract-detail-input-2 {
    width: 440px;
    background: #ffffff;
    border: 1px solid #181743 !important;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 10px !important;
  }
  
  .contract-properties-ipfs-files {
    margin-top: 60px;
  }
  
  .contract-detail-ipfs-input {
    width: 60%;
    background: #ffffff;
    border: 1px solid #181743 !important;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 10px !important;
  }
  
  .contract-detail-owner-input {
    width: 520px;
    background: #ffffff;
    border: 1px solid #181743 !important;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    margin-right: 10px !important;
  }
  
  .update-owner-button-loading {
    /* width: 200px; */
    margin-left: 20px;
    margin-top: 10px;
  }
  
  .mt-10-input {
    margin-top: 8px !important;
  }
  
  button.mint-tokens-button {
    background-color: #5034ff;
    padding: 20px 60px;
    color: white;
    border-radius: 28px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
  }
  
  .mint-tokens-button-loading {
    width: 200px;
    margin-left: 20px;
    margin-top: 20px;
  }
  
  .detail-section-inline-form-mint {
    width: 100%;
    height: 70px;
    display: flex;
  }
  
  input#mint-tokenId-input {
    font-size: 20px;
  }
  
  input#mint-wallet-input {
    font-size: 20px;
  }
  
  .detail-section-collection-description {
    padding: 50px;
    background: rgba(97, 103, 255, 0.04);
    border-radius: 30px;
    text-align: left;
    margin-bottom: 80px;
  }
  
  .detail-section-collection-description h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  .detail-section-collection-description h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  .detail-section-collection-description h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #181743;
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  .detail-section-collection-description p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-bottom: 25px;
    margin-top: 0;
  }
  
  .detail-section-collection-description-form {
    display: flex;
  }
  
  .contract-detail-desc-input {
    width: 70%;
  }
  
  input#collection-description-input {
    font-size: 20px;
  }
  
  input#contract-uri-input {
    font-size: 20px;
  }
  
  input#metadata-name-input,
  input#metadata-description-input,
  input#metadata-website-input {
    font-size: 16px;
  }
  
  .update-contract-button {
    background-color: #5034ff;
    padding: 18px 20px;
    color: white;
    border-radius: 28px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
  }
  
  .update-contract-button:hover {
    background: #dfe8ff;
    border-color: #dfe8ff;
    color: #5034ff;
  }
  
  .upload-edit-button {
    border: 2px solid #5034ff;
    padding: 8px 22px;
    background-color: transparent;
    color: #5034ff;
    border-radius: 28px;
    font-weight: 600;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .upload-confirm-button {
    border: 2px solid #5034ff;
    background-color: #5034ff;
    padding: 8px 22px;
    color: white;
    border-radius: 28px;
    font-weight: 600;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
  }
  
  .contract-detail-update-uri-input-row {
    display: flex;
  }
  
  .update-uri-button-loading {
    width: 200px;
    margin-left: 20px;
    margin-top: 10px;
  }
  
  .contract-properties-metadata {
    padding: 60px;
    text-align: left;
    background: linear-gradient(
      266.18deg,
      rgba(123, 97, 255, 0.5) 17.16%,
      rgba(255, 206, 64, 0.5) 90.07%
    );
    border-radius: 30px;
    margin-bottom: 40px;
  }
  
  .contract-properties-metadata-header h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 40px;
  }
  
  .contract-properties-metadata-header h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #000;
    line-height: 36px;
    margin-bottom: 40px;
    margin-top: 0;
  }
  
  .cp-metadata-body-nft-overview {
    max-width: 400px;
    /* min-height: 280px; */
    background: #ffffff;
    box-shadow: 0px 0px 47px #bebdff;
    border-radius: 35px;
  }
  
  .cp-metadata-body-nft-overview-top {
    background-color: #f1f5fd;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    min-height: 400px;
  }
  
  .uploader-component {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    /* padding: 100px; */
    height: 400px;
    position: relative;
    text-align: center;
  }
  
  .uploader-component input[type="file"] {
    display: none;
  }
  
  .uploader-component-inside {
    margin: 0 auto;
    position: absolute;
    left: 145px;
    top: 140px;
  }
  
  .uploader-component-bottom {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  
  .uploader-component img {
    max-width: 400px;
    overflow: auto;
  }
  
  .uploader-clc-component {
    display: block;
    width: 400px;
    /* height: 400px; */
  }
  
  .upload-btn {
    border: 3px solid #5034ff;
    border-radius: 28px;
    padding: 8px 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    background: #5034ff;
    /* position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 150px; */
  }
  
  .upload-btn:hover {
    border: 3px solid #5034ff;
    color: #5034ff;
    background: #dfe8ff;
  }
  
  .upload-btn-change {
    border: 3px solid #dfe8ff;
    border-radius: 28px;
    padding: 8px 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #2407d8;
    background: #dfe8ff;
    /* position: absolute;
    top: 100px;
    left: 40%; */
    margin-bottom: 20px;
  }
  
  .upload-btn-change:hover {
    border: 3px solid #5034ff;
    /* color: #fff;
    background: #5034ff; */
  }
  
  .upload-btn-loader {
    position: absolute;
    bottom: 10px;
    left: 45%;
  }
  
  .uploader-component-inside img {
    margin-bottom: 20px;
    z-index: -1;
  }
  
  .uploader-component p {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #5034ff;
  }
  
  .uploaded-image-item img {
    max-width: 500px;
  }
  
  .cp-metadata-body-nft-overview-bottom {
    padding: 60px 40px;
    min-height: 300px;
  }
  
  .cp-metadata-data-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
  }
  
  .cp-metadata-data-name {
    font-family: "Millik";
    font-size: 24px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
  }
  
  .cp-metadata-update-name-input {
    display: flex;
  }
  
  .cp-metadata-body-gallery-button {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  
  .cp-metadata-extra-big-button {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    background: #5034ff;
    border-radius: 40px;
    border-color: #5034ff;
    padding: 24px;
    width: 100%;
  }
  
  .cp-metadata-extra-big-button:hover {
    color: #5034ff;
    background: #dfe8ff;
    border-color: #dfe8ff;
  }
  
  .cp-metadata-modal-gallery-body {
    background: #fff;
    width: 900px;
    margin: 0 auto;
    border-radius: 35px;
    margin-top: 45px;
  }
  
  .modal-gallery-header {
    background: linear-gradient(265.07deg, #d0d0ff -16.86%, #e4fef3 91.6%);
    position: relative;
    border-radius: 35px 35px 0px 0px;
    padding: 25px 50px;
  }
  
  .modal-gallery-header h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin: 0;
  }
  
  .modal-gallery-header img {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  
  .modal-gallery-header img:hover {
    cursor: pointer;
  }
  
  .modal-gallery-sidemenu {
    background: rgba(221, 230, 234, 0.6);
    padding: 40px 40px;
    min-height: 550px;
  }
  
  .modal-gallery-sidemenu ul {
    padding: 0;
  }
  
  .modal-gallery-sidemenu ul li {
    list-style: none;
    margin-bottom: 12px;
  }
  
  .modal-gallery-sidemenu ul li a {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }
  
  .modal-gallery-sidemenu ul li a:hover {
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-gallery-sidemenu ul li a.activestate {
    color: #2407d8;
  }
  
  .modal-gallery-sidemenu ul li a.deactivestate {
    color: #bec7cc;
    cursor: default;
  }
  
  .modal-gallery-footer {
    position: relative;
    display: flow-root;
  }
  
  .modal-gallery-footer button {
    float: right;
    margin: 25px;
  }
  
  .cp-metadata-body-nft-description {
    /* max-width: 400px; */
    min-height: 280px;
    background: #ffffff;
    box-shadow: 0px 0px 47px #bebdff;
    border-radius: 35px;
    padding: 40px;
    margin-bottom: 20px;
  }
  
  .cp-metadata-data-description-title,
  .cp-metadata-data-project-website-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #000;
    margin-bottom: 30px;
  }
  
  .cp-metadata-data-description-data,
  .cp-metadata-data-website-data {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 50px;
  }
  
  .cp-metadata-update-description-input,
  .cp-metadata-update-website-input {
    display: flex;
  }
  
  .cp-metadata-body-nft-project-website {
    /* max-width: 400px; */
    min-height: 280px;
    background: #ffffff;
    box-shadow: 0px 0px 47px #bebdff;
    border-radius: 35px;
    padding: 40px;
  }
  
  .contract-properties-ipfs-files h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 40px;
  }
  
  .contract-properties-ipfs-files-text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #000;
    line-height: 36px;
    margin-bottom: 40px;
    margin-top: 0;
  }
  
  .contract-properties-ipfs-files-setup {
    margin-bottom: 25px;
  }
  
  .proplabel-out {
    padding-top: 15px;
  }
  
  .contract-properties-metadata-option {
    /* margin-bottom: 80px; */
    overflow-y: scroll;
    overflow-x: hidden;
    height: 550px;
  }
  
  .contract-properties-metadata h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 30px;
  }
  
  .metadata-upload-img-section {
    max-width: 280px;
  }
  
  .proplabel {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }
  
  .contract-properties-metadata-option-row {
    margin-bottom: 40px;
  }
  
  .default-metadata-option-item {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 98%;
  }
  
  .default-metadata-option-item:hover {
    background-color: rgba(221, 230, 234, 0.3);
  }
  
  .update-modal-image-link-loading {
    float: right;
    padding: 20px;
  }
  
  .default-metadata-option-item label {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }
  
  .default-metadata-option-item input {
    margin-right: 10px;
  }
  
  .default-metadata-option-item img {
    width: 115px;
  }
  
  .default-metadata-price {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #5034ff;
  }
  
  .image-item__btn-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .detail-section-public-properties {
    text-align: left;
  }
  
  .detail-section-public-properties h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  
  .detail-section-public-properties h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #000;
    line-height: 36px;
    margin-bottom: 50px;
    margin-top: 0;
  }
  
  .contract-property-row p {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }
  
  .contract-property-row p a {
    cursor: pointer;
  }
  
  .contract-property-row span.proplabel {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #000;
  }
  
  .contract-property-row {
    padding-bottom: 30px;
    /* margin-bottom: 20px; */
  }
  
  .save-extra-big-button {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    background: #5034ff;
    border-radius: 40px;
    border-color: #5034ff;
    padding: 24px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  
  .save-extra-big-button .MuiCircularProgress-colorPrimary {
    color: #fff;
  }
  
  .section-hr {
    border-top: 1px dotted #dde6ea;
    margin-bottom: 50px;
  }
  
  .section-hr-mint {
    border-top: 1px dotted #dde6ea;
    margin-bottom: 50px;
  }
  
  .transfer-contract-ownership-section-header {
    border-top: 1px dotted #dde6ea;
  }
  
  .mint-section-header h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: left;
  }
  
  .transfer-contract-ownership-section-header h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: left;
  }
  
  .detail-section-transfer-ownership {
    padding: 50px;
    background: rgba(221, 230, 234, 0.5);
    border-radius: 30px;
    text-align: left;
    margin-bottom: 50px;
  }
  
  .detail-section-transfer-ownership h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 10px;
    margin-top: 0;
  }
  
  .detail-section-transfer-ownership p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #000;
    margin-bottom: 15px;
  }
  
  .detail-section-transfer-ownership-label {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }
  
  .detail-section-transfer-ownership-label-data p {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #000;
  }
  
  .detail-section-update-owner-textfield {
    text-align: right;
    padding-top: 25px;
    display: flex;
  }
  
  .detail-section-update-owner-textfield small {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
  }
  
  .transfer-ofnership-price {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000;
  }
  
  input#contract-name-input {
    font-size: 20px;
  }
  
  .detail-section-forget-contract {
    text-align: left;
    margin-bottom: 40px;
  }
  
  .detail-section-forget-contract button {
    background: #ffffff;
    border: 1px solid #7f8a8f;
    border-radius: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #3f474b;
    padding: 16px;
  }
  
  .detail-section-minted-tokens h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #000;
    margin-bottom: 50px;
    margin-top: 50px;
    text-align: left;
  }
  
  .minted-item {
    max-width: 250px;
    box-shadow: 0px 0px 47px #bebdff;
    border-radius: 35px;
    text-align: center;
    float: left;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  
  .minted-item-preview img {
    width: 250px;
  }
  
  .minted-item-properties h4 {
    font-family: "Millik";
    font-size: 24px;
    font-weight: 400;
    color: #000;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  
  .minted-item-properties p {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #181743;
    margin-bottom: 15px;
  }
  
  .minted-item-props-button-div {
    margin-bottom: 10px;
  }
  
  .minted-item-props-btn {
    border: 3px solid #2407d8;
    border-radius: 28px;
    padding: 12px 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2407d8;
    background: #fff;
  }
  
  .minted-item-props-links {
    padding-bottom: 15px;
  }
  
  .minted-item-props-links a img {
    padding: 10px;
  }

  .center-content {
    text-align: center;
  }
  
  @media screen and (max-width: 992px) {
    .droplove-page-dashboard-body {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .detail-test-net-indicator {
      text-align: center;
    }
  
    .pm-detail-top-header {
      padding: 0;
    }
  
    .detail-section-inline-form-mint {
      display: block;
    }
  
    .detail-section-collection-description {
      margin-top: 80px;
    }
  
    .detail-section-collection-description-form {
      display: block;
    }
  }
  
